import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Waitlist = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter a valid email.');
      return;
    }
    setLoading(true);

    const templateParams = {
      from_email: email,
    };

    emailjs
      .send(
        'service_yqwypgr', // EmailJS service ID
        'template_f1xslof', // EmailJS template ID
        templateParams,
        'JsWXMKoEmYGkVNzFs'
      )
      .then(
        (response) => {
          setLoading(false);
          setEmail('');
          setMessage('you\'re on the list. cow juice coming to your doorstep soon.');
          setError('');
        },
        (err) => {
          setLoading(false);
          setError('something went wrong. please try again later.');
        }
      );
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="flex flex-col items-start justify-start space-y-8">
        <p className="uppercase font-mono text-xs font-bold tracking-widest animate-flip-up">
          join the waitlist for the single most anticipated product in the history of mankind
        </p>

        {/* <div className="border-[0.5px] w-full border-gray-300" /> */}

        {/* <p className="font-mono text-xs animate-fade-down">
          Okay so people have been asking to join a waitlist, so here it is. Sign up to pre-order your first case of Cow Juice.
        </p> */}

        <form onSubmit={handleSubmit} className="flex flex-col w-full space-y-4 mt-8">
          <input
            type="email"
            placeholder="enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border-[0.25px] rounded-none border-black/50 p-2 w-full text-xs dark:text-black font-mono focus:outline-none"
            required
          />
          {error && <p className="text-xs text-red-500 font-mono">{error}</p>}
          <button
            type="submit"
            className={`bg-black dark:bg-white text-white dark:text-black text-xs font-mono p-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            {loading ? 'good choice ...' : 'sign up'}
          </button>
        </form>

        {message && <p className="text-xs font-mono">{message}</p>}

        <div className="border-[0.5px] w-full border-gray-300 mt-8" />

        <p className="font-mono text-xs animate-fade-down animate-delay-900">
          got ideas for us? reach out to us on social media.
        </p>

        <div className="flex flex-row space-x-4 mt-4">
          <a href="https://tiktok.com/@juiceofacow" className="text-xs font-mono">
            TikTok 🎵
          </a>
          <a href="https://www.instagram.com/juiceofacow" className="text-xs font-mono">
            Instagram 📸
          </a>
        </div>

      </div>
    </div>
  );
};

export default Waitlist;