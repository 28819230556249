import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { MasterContextProvider } from './contexts/MasterContext';

import './App.css';

import Error from './pages/Error';
import Home from './pages/Home';
import Ideas from './pages/Ideas';
import IdeaDetails from './pages/IdeaDetails';
import Projects from './pages/Projects';
import ProjectDetails from './pages/ProjectDetails';
import Photos from './pages/Photos';
import PhotoDetails from './pages/PhotoDetails';
import Contact from './pages/Contact';
import Waitlist from './pages/CowJuice/Waitlist';

import Header from './components/Header';

const App = () => {

  return (
    <Router> 
      <MasterContextProvider>
        <div class="bg-white text-black dark:bg-black dark:text-white">
          <Routes>
            <Route exact path="*" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Error />
                </div>
              }
            />
            <Route exact path="/" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Home />
                </div>
              }
            />
            <Route exact path="/ideas" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Header />
                  <Ideas />
                </div>
              }
            />
            <Route exact path="/ideas/:tag" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Header />
                  <IdeaDetails />
                </div>
              }
            />
            <Route exact path="/projects" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Header />
                  <Projects />
                </div>
              }
            />
            <Route exact path="/projects/:tag" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Header />
                  <ProjectDetails />
                </div>
              }
            />
            {/* <Route exact path="/photos" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Header />
                  <Photos />
                </div>
              }
            />
            <Route exact path="/photos/:tag" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Header />
                  <PhotoDetails />
                </div>
              }
            /> */}
            <Route exact path="/cowjuice/waitlist" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Header />
                  <Waitlist />
                </div>
              }
            />
            <Route exact path="/contact" element={
                <div class="flex flex-col min-h-screen p-4 space-y-4">
                  <Header />
                  <Contact />
                </div>
              }
            />
          </Routes>
        </div>
      </MasterContextProvider>
    </Router>
  );
};

export default App;
